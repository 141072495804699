import { StoreAction } from "../../@core/constants/action";

const initialState = {
  staffList: [],
  webSocket: null,
  isSubmitting: false,
  pendingRequests: [], // Store pending WebSocket requests
  customerLabelList: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case StoreAction.GET_ALL_STAFF_LIST:
      return { ...state, staffList: action.payload };
    case StoreAction.SET_WEB_SOCKET:
      return { ...state, webSocket: action.payload };
    case StoreAction.SET_IS_SUBMITTING:
      return { ...state, isSubmitting: action.payload };
    case StoreAction.GET_ALL_CUSTOMER_LABEL_LIST:
      return { ...state, customerLabelList: action.payload };
    case StoreAction.PENDING_REQUEST:
      return { ...state, pendingRequests: action.payload };
    default:
      return state;
  }
}
